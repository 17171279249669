.fade-enter {
  opacity: 0.01;
  transition: all 400ms ease-in;
}
.fade-enter-active {
  opacity: 1;
  transition: all 400ms ease-in;
}
.fade-exit {
  opacity: 1;
  transform: translateX(0);
}
.fade-exit-active {
  opacity: 0.01;
  transition: all 300ms ease-in;
  transform: translateX(-100px);
  margin-bottom: -500px;
}
